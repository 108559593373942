import logo from './iTunesArtwork@1x.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Music4Fun LLC
        </p>
        <a
          className="App-link"
          href="https://apps.apple.com/tt/developer/music4fun-llc/id1035195351"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS - You can download the apps for iOS devices here
        </a>
        <p></p>
        <a
          className="App-link"
          href="https://play.google.com/store/apps/developer?id=Paulo+R+Ribeiro"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android - You can download the apps for Android here
        </a>
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          2880 W Oakland Park Blvd Suite 225C - Oakland Park - Florida 33311 contact@music4fun.net
        </p>
      </header>
    </div>
  );
}

export default App;
